<template>
  <div class="view-terms py-5">
    <div class="container pb-5">
      <h1 class="text-center mb-5">
        <router-link :to="{ name: 'home' }">
          <img src="../assets/images/logo.png" />
        </router-link>
      </h1>
      <h2 class="mb-5">
        CONTRATO DE PRESTAÇÃO DE SERVIÇOS, POLÍTICA DE PRIVACIDADE E TERMOS DE
        SERVIÇO E UTILIZAÇÃO DO {{ company }}
      </h2>

      <h5 class="mt-4">1. DISPOSIÇÕES GERAIS</h5>
      <p>
        Caso o USUÁRIO não concorde com estes termos, ele não poderá utilizar os
        serviços colocados à disposição pela plataforma de automação e
        gerenciamento de perfis no Instagram, {{ company }}.
      </p>
      <p>
        Ao concordar com os termos das presentes condições gerais, o USUÁRIO
        autoriza que sejam realizados no perfil vinculado à plataforma as ações
        de seguir e curtir o perfil oficial da {{ company }} no Instagram e
        declara ter lido integralmente este contrato, tornando certo e perfeito
        o ato de contratação.
      </p>
      <p>
        A {{ company }} reserva para si o direito de alterar os termos e
        condições deste instrumento a qualquer momento, sem prévia comunicação
        ou autorização do USUÁRIO.
      </p>
      <p>
        A
        {{ company }} reserva para si o direito de modificar, suspender,
        alterar, aprimorar, retirar de funcionamento a totalidade ou parte de
        qualquer serviço e/ou funcionalidade, ou qualquer dos seus conteúdos, a
        qualquer tempo, sem prévia comunicação ou autorização do USUÁRIO, não
        incorrendo em qualquer responsabilidade de qualquer natureza,
        declarando-se o USUÁRIO desde já ciente desta prerrogativa da {{}}.
      </p>
      <p>
        O USUÁRIO concorda que o Instagram tem seus próprios conjuntos de termos
        que podem diferir dos nossos termos e condições e devem ser lidos e
        aceitos sob responsabilidade do cliente.
      </p>

      <h5 class="mt-4">2. CONDIÇÕES GERAIS DE USO</h5>
      <p>
        Estas condições gerais regulamentam a utilização da plataforma de
        automação de perfis no Instagram oferecida por {{ company }}, registrado
        sob o endereço eletrônico https://www.scalehot.com
      </p>
      <p>
        Uma cópia atualizada destas condições gerais estará à disposição através
        do endereço https://app.scalehot.com/termos-de-uso/.
      </p>
      <p>
        A expressa aceitação deste termos de uso, atribui a condição de USUÁRIO
        do serviço (doravante, denominado “USUÁRIO”) que por este ato declara a
        aceitação plena e sem reservas do USUÁRIO a cada uma das condições
        gerais, nos termos da versão publicada pelo {{ company }} no exato
        momento em que utilizar o serviço. Todos os demais avisos, regulamentos,
        normativos e instruções integram e complementam as presentes condições
        gerais.
      </p>
      <p>
        O USUÁRIO concorda desde já em usar os sites da {{ company }} unicamente
        para fins lícitos e não comprometer a segurança dos referidos sites,
        tornar inacessível a outras pessoas ou causar danos ao conteúdo do
        serviço ou website, sob pena de responder civil e criminalmente pelos
        prejuízos causados.
      </p>
      <p>
        O USUÁRIO concorda ainda em abster-se de realizar ou executar qualquer
        ação que possa adicionar, subtrair ou modificar o conteúdo do seus
        sites, bem como de acessar conteúdo sem permissão e de usar o site de
        qualquer outra forma diversa a qual ele se destina.
      </p>
      <p>
        A {{ company }} reserva exclusivamente para si o direito de corrigir
        eventuais erros ou imprecisões no conteúdo dos seus sites, alterando-os
        e atualizando-os, a qualquer momento, sem aviso prévio.
      </p>

      <h5 class="mt-4">3. OBJETO</h5>
      <p>
        Este contrato regulamenta a utilização dos serviços da plataforma
        {{ company }}
        de gerenciamento de conteúdo no Instagram. A ferramenta colocará à
        disposição um painel administrativo que permite enviar ou agendar
        publicações.
      </p>
      <p>
        Os dados referentes aos conteúdos ficarão armazenados dentro do sítio do
        {{ company }} em servidores próprios ou contratados.
      </p>
      <p>
        O USUÁRIO é o único e exclusivo responsável por manter a
        confidencialidade de suas senhas de acesso e compromete-se a utilizá-las
        diligentemente, bem como a notificar aos administradores da plataforma,
        imediatamente, em caso de perda, roubo ou de qualquer uso não autorizado
        das mesmas.
      </p>
      <p>
        Ao se registrar em nossa plataforma, o USUÁRIO concorda e autoriza desde
        já a utilização de dados como: seu nome e seu perfil no Instagram, por
        exemplo, para promover nosso sistema.
      </p>

      <h5 class="mt-4">4. O SERVIÇO</h5>
      <p>
        O USUÁRIO, após concordar com as presentes condições gerais e termos de
        Uso, terá acesso à plataforma {{ company }} de gerenciamento.
      </p>
      <p>
        Os dados referentes aos conteúdos gerenciados serão armazenados dentro
        do sítio e dos provedores do {{ company }} ou contratados.
      </p>
      <p>
        Sujeito à lei aplicável, o USUÁRIO entende e concorda expressamente que
        a {{ company }}, seus afiliados, executivos, funcionários, agentes,
        parceiros e licenciadores não serão, sob qualquer hipótese, responsáveis
        por quaisquer danos diretos, indiretos, especiais ou consequentes por
        perda de lucro ou outras perdas suportadas pelos USUÁRIOs de sua
        plataforma, qualquer que seja a natureza desses prejuízos.
      </p>

      <h5 class="mt-4">5. CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SERVIÇO</h5>
      <p>
        O USUÁRIO reconhece que é essencial para utilização do serviço o
        preenchimento correto e obediência às regras impostas no sistema de
        cadastro do
        {{ company }}, obrigando-se a preenchê-lo com dados verdadeiros e
        isentando o {{ company }}
        de qualquer responsabilidade quanto a prejuízos gerados por dados falsos
        ou imprecisos que sejam fornecidos no referido cadastro.
      </p>
      <p>
        O USUÁRIO compromete-se a utilizar o Serviço em conformidade com as
        presentes Condições Gerais e demais normas e regulamentos do
        {{ company }}
        aplicáveis ao serviço, bem como em conformidade com as Leis Brasileiras,
        a moral e os bons costumes e a ordem pública, utilizando o serviço de
        forma adequada se abstendo da prática de atos ilícitos, proibidos pela
        lei e pelas presentes Condições Gerais, lesivos aos direitos e
        interesses de terceiros, ou que, de qualquer forma, possa danificar,
        inutilizar, sobrecarregar ou deteriorar o serviço, os servidores ou os
        equipamentos de outros USUÁRIOs ou de outros internautas (“hardware” e
        “software”) através da prática de “hacking”.
      </p>
      <p>
        É responsabilidade exclusiva do USUÁRIO a manutenção de cópias locais de
        seus arquivos, uma vez que o serviço prestado pelo
        {{ company }} não contempla o serviço de backup dos materiais
        disponíveis em seu sítio, não sendo portanto o {{ company }} responsável
        por eventuais danos que o USUÁRIO possa vir a sofrer decorrentes da
        perda, por qualquer motivo, do material inserido ou armazenado nos
        sítios do {{ company }}.
      </p>
      <p>
        O serviço não poderá ser utilizado com a finalidade de armazenar,
        distribuir, transmitir, difundir ou pôr à disposição de terceiros,
        qualquer tipo de conteúdo que, por si mesmo, ou cuja transmissão:
      </p>
      <p>
        I. Contravenha, menospreze ou atente contra os direitos fundamentais e
        liberdades públicas e individuais reconhecidas no ordenamento jurídico
        brasileiro ou em tratados internacionais; induza, incite ou promova
        atuações delituosas, difamatórias, infamantes, violentas ou, contrárias
        à lei, à moral e aos bons costumes ou à ordem pública;
      </p>
      <p>
        II. Induza, incite ou promova atuações, atitudes ou ideias
        discriminatórias em razão de sexo, raça, religião, crenças, idade ou
        condição social;
      </p>
      <p>
        III. Incorpore, ponha à disposição ou permita acessar produtos,
        elementos, mensagens delituosas, violentas, pornográficas, degradantes
        ou, em geral, contrárias à lei, à moral e aos bons costumes ou à ordem
        pública;
      </p>
      <p>
        IV. Esteja protegido por quaisquer direitos de propriedade intelectual
        ou industrial pertencentes a terceiros, sem que o USUÁRIO tenha obtido
        previamente dos seus titulares a autorização necessária para realizar o
        uso que efetua ou pretende efetuar; caso em que o USUÁRIO será
        responsável por quaisquer danos, eximindo desde já o {{ company }} de
        qualquer responsabilidade ou corresponsabilidade;
      </p>
      <p>
        V. Seja contrário ao direito, à honra, à intimidade pessoal e familiar
        ou à própria imagem das pessoas;
      </p>
      <p>
        VI. Constitua publicidade ilícita, enganosa ou desleal e, em geral, que
        constitua concorrência desleal;
      </p>
      <p>
        VII. Contribua, facilite ou incentive, de qualquer forma, a prática de
        quaisquer formas de infração aos direitos de propriedade intelectual de
        qualquer conteúdo disponibilizado na internet.
      </p>
      <p>
        § 1º Em qualquer das hipóteses acima, o {{ company }} poderá, a seu
        exclusivo critério, cancelar o acesso do USUÁRIO e eliminar o respectivo
        conteúdo, a qualquer tempo e sem qualquer aviso prévio, nem indenização.
      </p>
      <p>
        § 2º Qualquer decisão sobre a aplicação, ou não, das presentes Condições
        Gerais, será tomada segundo exclusivo critério do
        {{ company }} que poderá ainda, notificar as autoridades policiais e
        judiciais acerca da existência de conteúdo de USUÁRIOs que contenham
        qualquer classe de material que, a juízo do {{ company }}
        seja considerado ilegal, fornecendo, ainda, cópias desse conteúdo e
        demais documentos dos USUÁRIOs, independentemente de prévio
        consentimento.
      </p>
      <p>
        O USUÁRIO compromete-se a utilizar a plataforma de forma sã e
        bem-intencionada, sendo assim, o {{ company }} reserva-se o direito de
        suspender quaisquer contas que façam abuso da plataforma através de
        quaisquer meios disponíveis e para quaisquer finalidades.
      </p>
      <p>
        O USUÁRIO declara desde já que é o único e exclusivo responsável pelo
        conteúdo por ele inserido, não tendo o {{ company }} qualquer
        responsabilidade ou controle sobre este.
      </p>
      <p>
        Em decorrência da presente declaração, o USUÁRIO, desde já, obriga-se,
        caso venha a ser chamado a responder judicialmente pela prática de um
        ato ilícito ou ilegal em decorrência dos referidos conteúdos, a isentar
        o {{ company }} de quaisquer responsabilidades, bem como requerer a
        exclusão da mesma do processo, se for o caso.
      </p>
      <p>
        O {{ company }} reserva para si o direito de modificar, a qualquer
        momento, a apresentação e configuração do Serviço, assim como também as
        presentes Condições Gerais ou quaisquer outros regulamentos e normas que
        influenciem no Serviço, sem qualquer notificação ou aviso prévio.
      </p>
      <p>
        Caso ocorram eventos de força maior que não permitam o
        {{ company }}
        comunicar com antecedência alterações ou eventuais falhas no serviço, o
        USUÁRIO neste ato permite que o {{ company }}
        suspenda a prestação do serviço e rescinda este contrato, ficando desde
        já acertado que esta informará o ocorrido e sua causa para ciência do
        USUÁRIO.
      </p>
      <p>
        O {{ company }} não reembolsará pagamentos efetuados pelo USUÁRIO caso
        haja interrupção temporária ou permanente do serviço, e neste ato, o
        USUÁRIO expressamente concorda com tal política, sem quaisquer
        ressalvas.
      </p>
      <p>
        O USUÁRIO deverá manter sempre seu cadastro atualizado junto o
        {{ company }}, informando pelo menos um endereço de e-mail em
        funcionamento, para que possa receber comunicados e avisos.
      </p>
      <p>
        A {{ company }} não será responsável pelos comunicados não recebidos
        devidos a configurações de e-mail do USUÁRIO.
      </p>
      <p>
        Como resultado do uso dos serviços da
        {{ company }}, o USUÁRIO concorda que as rescisões de contas em outras
        plataformas (Instagram, etc.) são por conta e risco do próprio USUÁRIO e
        de forma alguma será responsabilidade da {{ company }}
        ou qualquer de suas partes afiliadas.
      </p>

      <h5 class="mt-4">6. USO E CUSTÓDIA</h5>
      <p>
        O USUÁRIO ao ter o serviço ativado, informará um “login” e uma senha de
        acesso ao painel administrativo. Ele deverá alterar a senha
        periodicamente, guardando consigo a nova senha escolhida.
      </p>
      <p>
        O USUÁRIO compromete-se a fazer um uso diligente das Senhas de Acesso,
        assim como a mantê-las em sigilo, conforme demais avisos, regulamentos e
        instruções fornecidas pelo {{ company }}.
      </p>
      <p>
        O USUÁRIO compromete-se a comunicar o {{ company }}, imediatamente, caso
        haja perda ou extravio das Senhas de Acesso assim como qualquer risco de
        acesso às mesmas por um terceiro.
      </p>
      <p>
        O {{ company }} não se responsabiliza por senhas de acesso extraviadas,
        perdidas, modificadas pelo USUÁRIO, ou por senhas cuja combinação seja
        considerada muito “fraca”, apta a ser forçada ou adivinhada por qualquer
        forma.
      </p>
      <p>
        Neste ato, o USUÁRIO expressamente autoriza a {{ company }} a colher e
        utilizar seus dados técnicos e operacionais presentes na PLATAFORMA,
        para fins de estudos e melhorias na PLATAFORMA.
      </p>

      <h5 class="mt-4">7. DA PROPRIEDADE INTELECTUAL E DIREITO DE IMAGEM</h5>
      <p>
        A {{ company }} possui plenos direitos de propriedade intelectual sobre
        todos os conteúdos e elementos visuais do site www.scalehot.com. Estas
        informações não podem ser usadas, copiadas, distribuídas, transmitidas,
        ou alteradas, a menos que expressamente autorizado pelo departamento de
        marketing.
      </p>
      <p>
        Embora não tenha acesso direto ao banco de dados do programa do
        {{ company }}, pertence ao USUÁRIO o conteúdo (arquivos e conteúdo
        inseridos em razão deste contrato), podendo tal conteúdo ser acessado
        através do painel administrativo.
      </p>
      <p>
        O USUÁRIO se responsabiliza, ainda, pelos direitos autorais de terceiros
        decorrentes de documentos inseridos, sejam fotos, vídeos ou textos, para
        efeitos de participação da promoção.
      </p>
      <p>
        O USUÁRIO concorda que a {{ company }} possa divulgar o fechamento
        contrato para fins comerciais, fazendo menção ao nome e à marca do
        USUÁRIO em campanhas comerciais, podendo, inclusive, divulgar imagens,
        para uso comerciais enquanto vigorar os serviços
      </p>

      <h5 class="mt-4">8. DIREITOS DO USUÁRIO</h5>
      <p>
        Ao contratar os serviços descritos pelo {{ company }}, o USUÁRIO possui
        os direitos de:
      </p>
      <p>
        I. Utilizar todos os serviços colocados à disposição pela plataforma
        objeto deste contrato.
      </p>
      <p>
        II. Hospedar os arquivos a ele referentes, enviar arquivos, utilizar
        bancos de dados, de acordo com as limitações impostas pelo serviço
        contratado;
      </p>

      <h5 class="mt-4">9. ALTERAÇÃO NO SERVIÇO, CASO FORTÚITO E FORÇA MAIOR</h5>
      <p>
        O USUÁRIO tem conhecimento e concorda que o Serviço pode mudar de tempos
        em tempos sem aviso prévio.
      </p>
      <p>
        Mudanças incluem, sem limitação, alterações na taxa e condição de
        pagamento, atualizações de segurança, funcionalidades adicionadas ou
        removidas e outras melhorias ou restrições.
      </p>
      <p>
        A plataforma não poderá ser responsabilizada pelo USUÁRIO ou terceira
        parte no caso de qualquer modificação, alteração de preço, suspensão ou
        descontinuação do serviço.
      </p>
      <p>
        O {{ company }} pode alterar as suas taxas e condições de pagamento para
        o serviço, sem aviso prévio, respeitando, todavia, a vigência dos planos
        já contratados sem cobrança adicional.
      </p>
      <p>
        O USUÁRIO declara neste ato estar ciente que em casos decorrentes de
        força maior, manutenção de emergência ou quedas inesperadas de serviço,
        não será possível notificá-lo com antecedência, não ensejando, em razão
        disto, qualquer responsabilidade para a {{ company }}.
      </p>
      <p>
        A {{ company }} não oferece qualquer tipo de garantia ou reembolso
        advindo da instabilidade decorrentes de fatos descritos nesta cláusula.
      </p>

      <h5 class="mt-4">10. POLÍTICAS DE COBRANÇA E PAGAMENTO</h5>
      <p>
        O serviço é prestado para o USUÁRIO mediante pagamento de valores
        previamente estipulados de acordo com o tipo de serviço oferecido.
      </p>
      <p>
        O USUÁRIO concorda desde já manter-se em dia com os pagamentos acordados
        com o {{ company }}, estando ciente que o atraso no pagamento poderá
        ensejar na interrupção imediata dos serviços.
      </p>
      <p>
        O USUÁRIO declara neste ato estar ciente que ao cancelar a assinatura, o
        Serviço poderá ser imediatamente interrompido e que não haverá
        ressarcimento de pagamento pelos dias não utilizados.
      </p>
      <p>
        A {{ company }} se reserva ao direito de renovar o plano contratado
        automaticamente e sucessivamente por iguais períodos, até que seja
        solicitado pelo USUÁRIO o cancelamento dos seus serviços.
      </p>

      <h5 class="mt-4">11. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</h5>
      <p>
        O {{ company }} exime-se de quaisquer responsabilidades quanto a danos e
        prejuízos de qualquer natureza porventura advindos devidos à
        indisponibilidade ou falta de continuidade do serviço.
      </p>
      <p>
        Privacidade e segurança na utilização do Serviço – Os dados do USUÁRIO
        serão armazenados com todo o sigilo e somente serão manipulados por
        funcionários do {{ company }}. No entanto, o {{ company }} não garante a
        privacidade e a segurança na utilização do Serviço por parte do USUÁRIO,
        e portanto, não pode garantir que terceiros não autorizados não possam
        acessar e, eventualmente, interceptar, eliminar, alterar, modificar ou
        manipular de qualquer modo o conteúdo posto à disposição no Serviço ou
        interceptar, eliminar, alterar, modificar ou manipular de qualquer modo
        os Arquivos e comunicações de qualquer classe que o USUÁRIO transmita,
        armazene ou ponha à disposição de terceiros através do Serviço.
      </p>
      <p>
        O {{ company }} se exime de toda responsabilidade pelos danos e
        prejuízos de qualquer natureza decorrente do acesso, interceptação,
        eliminação, alteração, modificação ou manipulação dos arquivos e
        comunicações armazenados, transmitidos ou postos à disposição por
        terceiros não autorizados a utilizar o Serviço.
      </p>

      <h5 class="mt-4">12. ATENDIMENTO E SUPORTE TÉCNICO</h5>
      <p>
        O {{ company }} prestará suporte técnico para seu USUÁRIO através do
        site https://www.scalehot.com mediante a abertura de chamados de
        suporte.
      </p>

      <h5 class="mt-4">13. POLÍTICA DE REEMBOLSO</h5>
      <p>
        O USUÁRIO dispõe do prazo de 15 (quinze) dias para testar o serviço
        gratuitamente e caso queira continuar a utilizar a
        {{ company }}, deverá efetuar o pagamento referente a assinatura mensal
        disponível no site da {{ company }}.
      </p>
      <p>
        Somente serão efetuados reembolsos desde que formalmente solicitado pelo
        USUÁRIO dentro do período de 7 (sete) dias, a contar da
        ativação/contratação dos serviços.
      </p>
      <p>
        Os reembolsos solicitados pelos USUÁRIOS, desde que preenchidos os
        requisitos de admissibilidade previstos neste instrumento e na
        legislação em vigor, serão efetuados pela {{ company }} em até 90
        (noventa) dias a contar da solicitação.
      </p>

      <h5 class="mt-4">14. FINALIZAÇÃO E TÉRMINO DOS SERVIÇOS</h5>
      <p>
        A vigência dos serviços terá início após a aceitação de pedido e terá
        fim quando anunciado por uma das partes mediante solicitação formal ou
        rescisão contratual.
      </p>
      <p>
        Os arquivos e dados inseridos no sítio do {{ company }} ficarão
        disponíveis para o USUÁRIO pelo prazo definido no plano contratado ou
        até o final da existência do serviço, se este for contratado na
        modalidade ilimitado.
      </p>
      <p>
        Para outros esclarecimentos entre em contato através do e-mail
        suporte@scalehot.com .
      </p>

      <h5 class="mt-4">15. FORO</h5>
      <p>
        As PARTES elegem o Foro da comarca de São Paulo - SP, como único
        competente para dirimir as dúvidas ou controvérsias decorrentes deste
        Contrato.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    company() {
      return "IEB";
    }
  }
};
</script>
